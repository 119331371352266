import { useEffect } from 'react';
import AOS from 'aos';

const SingleTestimonialTwo = (props) => {
    const { itemClass, animateName, animateDelay } = props;

    useEffect(() => {
        AOS.init();
    })

    return (
        <div  data-aos={animateName ? animateName : 'fade-up'} data-aos-delay={animateDelay ? animateDelay : ''}>
            {/* <div className="img-part">
                <img
                    src={props.testiImage}
                    alt={props.name}
                />
            </div> */}
            <div className="content-part pt-12">
                <blockquote>
                    {props.description}
                    <div className="info">
                        <h6 className="name">{props.name}</h6>
                        <div className="designation">{props.designation}</div>
                    </div>
                </blockquote>
            </div>
        </div>
    )
}

export default SingleTestimonialTwo