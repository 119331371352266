import React from 'react';
import { Link } from 'react-router-dom';
import { formatDateAndTime, serverLink, shortCode } from '../../resources/constants';

const RecentPost = (props) => {
    return (
        <div className="recent-posts-widget mb-50">
            <h3 className="widget-title">Recent Posts</h3>
            {
                props.pageData.length > 0 &&
                props.pageData.map((item, index) => {
                    return (
                        <div className="show-featured" key={index}>
                            <div className="post-img">
                                <Link to={`/${props.type === "news" ? "news-details" : "event-details"}/${item.Slug}`}>
                                    <img
                                        src={`${serverLink}public/uploads/${shortCode}/${props.type === "news" ? "news_uploads" : "event_uploads"}/${item.ImagePath}`}
                                        alt=""
                                        style={{ maxWidth: "100%", maxHeight: "100px" }}
                                    />
                                </Link>
                            </div>
                            <div className="post-desc">
                                <Link to={`/${props.type === "news" ? "news-details" : "event-details"}/${item.Slug}`}>{item.Title}</Link>
                                <span className="date">
                                    <i className="fa fa-calendar"></i>
                                    {formatDateAndTime(item.InsertedDate, "date")}
                                </span>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default RecentPost;