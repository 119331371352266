import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import CampusImage from '../../assets/images/campus.jpeg'
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import bannerbg from "../../assets/images/banner.jpg";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {connect} from "react-redux";

const OurCampus = (props) => {

    return (
        <React.Fragment>
            <OffWrap />

            <SiteBreadcrumb pageTitle="Our Campus"
                            pageName="Our Campus"
                            breadcrumbsImg={bannerbg} />

            <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 pr-70 md-pr-14">
                            <div className="about-content">
                                <div className='sec-title mb-44 md-mb-0'>
                                    <h3 className="title mb-10" style={{color: "#273c66"}}><strong>Our Campus</strong></h3>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: props.homeData.CampusContent }} style={{textAlign: "justify"}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5  padding-0 md-pl-14 md-pr-14 md-mb-30">
                            <div className="img-part">
                                <img className="about-main" src={CampusImage} alt="About Image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 pr-70 md-pr-14" style={{marginTop: "40px"}}>
                        <div className="about-content text-justify">
                            {/* Section Title Start */}
                            <div className='sec-title mb-44 md-mb-0'>
                                <div className='title mb-16'>
                                    <Accordion defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className={"text-white"} />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header" style={{backgroundColor: "#273c66"}}
                                        >
                                            <div className="text-white">
                                                <span ><h5 className="text-white">Our Vision</h5></span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p  style={{textAlign: "justify", fontSize:"15px"}}> The vision of the proposed Lux Mundi University, Umuahia is “to be a world-class institution that offers students the golden key to a bright future through high quality integrated education”. The University aims to be among the best Universities in the world, promoting excellence and industry, touching lives globally through excellent and quality tertiary education that shall rank favorably among the best universities in the world.
                                            </p>
                                            <p style={{textAlign: "justify", fontSize:"15px"}}>
                                                The university intend to make Nigeria the new global hub of deeper academic endeavors, through which the cure of her endemic challenges of tertiary education admissions would be realized
                                            </p>

                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 pr-70 md-pr-14">
                        <div className="about-content text-justify">
                            <div className='sec-title mb-44 md-mb-0'>
                                <div className='title mb-16'>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className={"text-white"} />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header" style={{backgroundColor: "#273c66"}}
                                        >
                                            <div className="text-white">
                                                <span><h5 className="text-white">Our Mission</h5></span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p  style={{textAlign: "justify", fontSize:"15px"}}> The mission of the proposed Lux Mundi University is:
                                                “To encourage students to pursue enlightenment critically and to engage the world with creativity and professionalism”.
                                                “To produce graduates outstanding in character, sound knowledge and specialised skills”; and
                                                “To create a conducive environment that enhances research, teaching and learning”.
                                                The law establishing the proposed Lux Mundi University, Umuahia aims to achieve the following objectives to:
                                            </p>
                                            <ol type="i" style={{textAlign: "justify", fontSize:"15px"}}>
                                                <li>To be a catalyst institution committed to revolutionizing the development and management of private University education in Nigeria with a view to progressively becoming a leading national, continental and global brand in all her courses and programmes; through the amelioration of infrastructural facilities deficit, responsible for lower admission intakes into Nigerian universities by providing same at higher quality standards, quantity and at most socially competitive fees.</li>
                                                <li>To pursue the revolution with higher intensity research into developing and sustaining of Africa’s businesses, infrastructures and improvement of public institutions management on the continent to support and boost Africa’s industrialization.</li>
                                                <li>To systematically through knowledge transfer, impact and leadership re-orientation, ensure that the over 85% of publicly funded institutions and infrastructure failures on African continent is reversed through a well-coordinated research using Nigeria as a case study.</li>
                                                <li>To academically build a conscious base through an expanded and integrated course structures and programmes that will develop and promote African Business Education and Environment (ABEE) contents; and to bridge the global knowledge gap, that Nigeria, and indeed Africa shall in two decades become a knowledge power house.</li>
                                                <li>And to structurally craft a University model that will through research and development promote and graduate first grade professionals, technologists and scientists in the low, middle and higher levels manpower base to support and boost the quest for industry, mines, agriculture and national industrialization in the Federal Republic of Nigeria</li>
                                            </ol>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 pr-70 md-pr-14">
                        <div className="about-content text-justify">
                            <div className='sec-title mb-44 md-mb-0'>
                                <div className='title mb-16'>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className={"text-white"} />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header" style={{backgroundColor: "#273c66"}}
                                        >
                                            <div className="text-white">
                                                <span><h5 className="text-white">Our Motto</h5></span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p  style={{textAlign: "justify", fontSize:"15px"}}> The motto of the proposed Lux Mundi University, Umuahia is ‘Illuminating Humanity’ which means that the University is to show light to humanity and the world to see as a citadel of learning. To illuminate is to show the light or to brighten a place. Thus, the motto is aimed at putting people, humans out of darkness, to see the light and face the reality of life, as to make progress for the good of all.
                                            </p>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};
export default connect(mapStateToProps, null)(OurCampus);