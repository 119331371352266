import React from 'react';
import { Link } from 'react-router-dom';

const CategoriesWidget = (props) => {
    return (
        <div className="widget-archives mb-50">
            <h3 className="widget-title">Categories</h3>
            <ul>
                <li ><Link to="#" onClick={() => props.onCategoryChange('all')}>All</Link></li>
                {
                    props?.categories.map((item, index) => {
                        return (
                            <li key={index}><Link to="#" onClick={() => props.onCategoryChange(item)}>{item}</Link></li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default CategoriesWidget;