import React from 'react'

export default function Loader() {
    return (
        <div>
            <div id="loader" className="loader">
                <div className="loader-container">
                    <div className="loader-icon">
                        <div className='p-3 m-3'>
                            <h3>Loading...</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
