import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// Breadcrumbs Bg
import teamImg1 from '../../assets/images/avater.jpg';
import { connect, useDispatch } from "react-redux";
import { setHRStaffDataDetails, setHRStaffListDetails } from "../../actions/setactiondetails";
import { ImageUrlFormatter, serverLink, shortCode } from "../../resources/constants";
import axios from "axios";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as DOMPurify from 'dompurify';

const TeamSingleMain = (props) => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    //const navigate = useNavigate();
    const [staffRelatedDocuments, setStaffRelatedDocuments] = useState({
        qualifications: [],
        modules: [],
        publications: [],
    });
    let staffRecord = props.hrStaffList.filter((item) => item.Slug === slug);
    const [staffDetail, setStaffDetail] = useState(
        staffRecord.length > 0
            ? props.hrStaffData.filter(
                (item) => item.StaffID === staffRecord[0].StaffID
            )
            : []
    );

    useEffect(() => {
        const slug_record = slug;
        staffRecord.push(
            props.hrStaffList.filter((item) => item.Slug === slug_record)
        );
        setStaffDetail(
            staffRecord.length > 0
                ? props.hrStaffData.filter(
                    (item) => item.StaffID === staffRecord[0].StaffID
                )
                : []
        );
    }, [slug])

    const getStaffData = async () => {
        await axios
            .get(`${serverLink}academic/staff/all/`)
            .then((response) => {
                const data = response.data;
                const staff_data = data.filter((item) => item.Slug === slug);
                props.setOnHRStaffListDetails(response.data);
                if (data.filter((item) => item.Slug === slug).length < 1) {
                    window.location.href = "/"
                } else {
                    staffRecord.push(staff_data);
                }
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academic/staff/data/`)
            .then((response) => {
                props.setOnHRStaffDataDetails(response.data);
                setStaffDetail(
                    response.data.filter(
                        (item) => item.EntryID === staffRecord[0].EntryID
                    )
                );
                if (staffDetail.length < 1) window.location.href = "/";
                else console.log("");
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };
    useEffect(() => {
        getStaffData().then((r) => { });
        getStaffRelatedDocument().then((r) => { });
    }, []);

    const getStaffRelatedDocument = async () => {
        await axios
            .get(`${serverLink}academic/staff/${staffRecord[0].StaffID}`)
            .then((response) => {
                setStaffRelatedDocuments(response.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    const updateDownloadCount = async (id) => {
        await axios
            .put(`${serverLink}academic/update/download/count/${id}`)
            .then((response) => { })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    const [qoutes, setQuotes] = useState([])


    useEffect(() => {
        axios.get("https://type.fit/api/quotes").then((res) => {
            const item = res.data[Math.floor(Math.random() * res.data.length)];
            setQuotes(item);
        })
    }, [])

    return (
        <div className="profile-section pt-100 pb-90 md-pt-80 md-pb-70">
            <div className="container">
                <div className="row clearfix">
                    <div className="image-column col-lg-5 md-mb-50">
                        <div className="inner-column mb-50 md-mb-0">
                            <div className="image">
                                <img
                                    src={ImageUrlFormatter(staffDetail[0].Image, "hr/document", teamImg1)}
                                    alt="images"
                                    style={{ maxHeight: "450px" }}
                                    height={"300px"} />
                            </div>
                            <div className="team-content text-center">
                                <h3>{staffDetail[0].FirstName} {staffDetail[0].MiddleName} {staffDetail[0].Surname}</h3>
                                <div className="text">{staffDetail[0].DesignationName}</div>
                                <ul className="personal-info">
                                    <li className="email">
                                        <span><i className="glyph-icon flaticon-email"> </i> </span>
                                        <Link to="#">{staffDetail[0].EmailAddress}</Link>
                                    </li>
                                    <li className="phone">
                                        <span><i className="glyph-icon flaticon-call"></i></span>
                                        <Link to="#">{staffDetail[0].PhoneNumber}</Link>
                                    </li>
                                    <div className="text">Profile Hit: {staffDetail[0].Hits}</div>
                                </ul>
                            </div>

                            <div className="social-box">
                                <Link to="#">
                                    <i className="fa fa-facebook-square"></i>
                                </Link>
                                <Link to="#">
                                    <i className="fa fa-twitter-square"></i>
                                </Link>
                                <Link to="#">
                                    <i className="fa fa-linkedin-square"></i>
                                </Link>
                                <Link to="#">
                                    <i className="fa fa-github"></i>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="content-column col-lg-7 pl-60 pt-50 md-pl-14 md-pt-0">
                        <div className="inner-column">
                            {
                                staffDetail[0]?.Biography !== "" &&
                                <>
                                    <h5>About Me</h5>
                                    <p style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(staffDetail[0]?.Biography) }} />
                                    </>
                            }

                            <div className="rs-testimonial style2">
                                <div className="col-lg-12 lg-pl-0 ml--15 md-ml-0">
                                    <div className="testi-wrap">
                                        <div className="content-part new-content pt-12">
                                            <div className="desc m-5">
                                                {qoutes.text}
                                            </div>
                                            <div className="info">
                                                <h6 className="name">{qoutes.author}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <Accordion className="mb-20">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <div>
                                        <span ><h5>Qualification(s)</h5></span>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <table className="table table-responsive-md">
                                        <tbody>
                                            <tr style={{ background: '#eee' }}>
                                                <th>S/No</th>
                                                <th>Field</th>
                                                <th>Institute</th>
                                                <th>Year Acquired</th>
                                            </tr>
                                            {staffRelatedDocuments.qualifications.length > 0 ?
                                                <>
                                                    {
                                                        staffRelatedDocuments.qualifications.map((x, y) => {
                                                            return (
                                                                <tr style={{ textAlign: 'left' }} key={y}>
                                                                    <td>{y + 1}</td>
                                                                    <td>{x.Discipline}</td>
                                                                    <td>{x.InstitutionName}</td>
                                                                    <td>{x.Year}</td>
                                                                </tr>
                                                            )

                                                        })}
                                                </> :
                                                <>
                                                    <tr>
                                                        <td>No Qualifications</td>
                                                    </tr>
                                                </>}
                                        </tbody>
                                    </table>

                                </AccordionDetails>
                            </Accordion>
                            <Accordion className="mb-20">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <div>
                                        <span ><h5>Teaching Responsibility</h5></span>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <table className="table table-responsive-md">
                                        <tbody>
                                            <tr style={{ background: '#eee' }}>
                                                <th>S/No</th>
                                                <th>Module Code</th>
                                                <th>Module Description</th>
                                                <th>Credit Unit</th>
                                            </tr>
                                            {staffRelatedDocuments.modules.length > 0 ?
                                                <>
                                                    {
                                                        staffRelatedDocuments.modules.map((x, y) => {
                                                            return (
                                                                <tr style={{ textAlign: 'left' }} key={y}>
                                                                    <td>{y + 1}</td>
                                                                    <td>{x.ModuleCode}</td>
                                                                    <td>{x.ModuleDescription}</td>
                                                                    <td>{x.CreditUnit}</td>
                                                                </tr>
                                                            )

                                                        })}
                                                </> :
                                                <>
                                                    <tr>
                                                        <td>You are not assign to any module yet </td>
                                                    </tr>
                                                </>}
                                        </tbody>
                                    </table>

                                </AccordionDetails>
                            </Accordion>
                            <Accordion className="mb-20">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <div>
                                        <span ><h5>Publication(s)</h5></span>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <table className="table table-responsive-md">
                                        <tbody>
                                            <tr style={{ background: '#eee' }}>
                                                <th>S/No</th>
                                                <th>Work Title</th>
                                                <th>Authors</th>
                                                <th>Published Year</th>
                                                <th>File</th>
                                                <th>Download/View</th>
                                            </tr>
                                            {staffRelatedDocuments.publications.length > 0 ?
                                                <>
                                                    {
                                                        staffRelatedDocuments.publications.map((x, y) => {
                                                            return (
                                                                <tr style={{ textAlign: 'left' }} key={y}>
                                                                    <td>{y + 1}</td>
                                                                    <td>{x.WorkTitle}</td>
                                                                    <td>{x.Authors}</td>
                                                                    <td>{x.PublishedYear}</td>
                                                                    <td><a href={`${serverLink}public/uploads/${shortCode}/academic/publication/${x.UploadFile ? x.UploadFile : ""}`}
                                                                        target="_blank"
                                                                        onClick={() =>
                                                                            updateDownloadCount(x.EntryID)
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        Download Paper
                                                                    </a></td>
                                                                    <td>{x.DownloadCount}/{x.ViewCount}</td>
                                                                </tr>
                                                            )

                                                        })}
                                                </> :
                                                <>
                                                    <tr>
                                                        <td>No Publication</td>
                                                    </tr>
                                                </>}
                                        </tbody>
                                    </table>

                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// export default TeamSingleMain;

const mapStateToProps = (state) => {
    return {
        hrStaffList: state.HRStaffListDetails,
        hrStaffData: state.HRStaffDataDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHRStaffListDetails: (p) => {
            dispatch(setHRStaffListDetails(p));
        },
        setOnHRStaffDataDetails: (p) => {
            dispatch(setHRStaffDataDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamSingleMain);