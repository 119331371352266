import React from 'react';
import { formatDateAndTime, serverLink, shortCode } from '../../resources/constants';
import Loader from '../Common/loader';

const PostContent = (props) => {

    const details = props.pagedetails[0];

    return (
        <div className="blog-deatails">
            {
                props.pagedetails.length > 0 ?
                    <>
                        <div className="bs-img">
                            <img
                                src={`${serverLink}public/uploads/${shortCode}/${props.type === "news" ? "news_uploads" : "event_uploads"}/${details.ImagePath}`}
                                alt="Post Image"
                                style={{ minWidth: '100%', minHeight: "400px", maxWidth: '100%', maxHeight: "400px" }}
                            />
                        </div>
                        <div className="blog-full">
                            <ul className="single-post-meta">
                                <li>
                                    <span className="p-date me-5"> <i className="fa fa-calendar-check-o"></i> {formatDateAndTime(details.InsertedDate, "date")} </span>
                                </li>
                                <li>
                                    <span className="p-date"> <i className="fa fa-user-o"></i> {details.FacultyCode} </span>
                                </li>
                            </ul>
                            <h2 className="title mb-40">{details.Title}</h2>
                            <div className="blog-desc">
                                <p dangerouslySetInnerHTML={{ __html: details?.Description }} />
                            </div>
                        </div></>
                    :
                    <Loader />
            }
        </div>
    )
}

export default PostContent;