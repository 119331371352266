import React from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { serverLink, shortCode } from '../../resources/constants'
import { useEffect } from 'react';
import { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const SliderStyleTwo = () => {

    const sliderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    const [sliderList, setSliderList] = useState([])
    const getData = async () => {
        const { data } = await axios.get(`${serverLink}slider/homepage`);
        setSliderList(data)
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className="rs-slider style1">
                <Carousel>
                    {
                        sliderList.length > 0 &&
                        sliderList.map((item, index) => {
                            return (
                                <div key={index} className='slider-content'
                                    style={{
                                        background: `url(${serverLink}public/uploads/${shortCode}/sliders_uploads/${item.ImagePath})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                    }} >





                                    <>

                                        {item.Title && item.Title.length > 0
                                            ? (
                                                <>
                                                    <div style={{
                                                        backgroundColor: "black", width: "auto", opacity: 0.8, color: "white"
                                                    }}>
                                                        <div className="sl-sub-title white-color">{item.Title}</div>
                                                        <p className='white-color'>{item.Caption}</p>
                                                        <div className="sl-btn">
                                                            <Link className="readon2 banner-style" to={`${item.BtnLink}`}>{item.BtnName}</Link>
                                                        </div>
                                                    </div>
                                                    {/* <div style={{ background: 'black',  position: "relative", zIndex: "5", marginTop: "250px" }}> */}

                                                    {/* </div> */}
                                                </>
                                            ) : (
                                                <div style={{ background: 'black', opacity: 0.8, position: "relative", zIndex: "5", marginTop: "250px" }}>
                                                    <div className="sl-btn">
                                                        <Link to={`${item.BtnLink}`}></Link>
                                                    </div>
                                                </div>
                                            )

                                        }
                                    </>


                                </div>
                            )
                        })

                    }

                </Carousel>

                {/* <Slider {...sliderSettings}>
                    {
                        sliderList.length > 0 &&
                        sliderList.map((item, index) => {
                            return (
                                <div key={index} className={`slider-content slide1`}
                                    style={{ background: `url(http://192.168.1.76:4480/public/uploads/sliders_uploads/${item.ImagePath})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                    <div className="container">
                                        <div className="sl-sub-title white-color">World Leading University</div>
                                        <h1 className="sl-title white-color">Educavo University</h1>
                                        <div className="sl-btn">
                                            <Link className="readon2 banner-style" to="/about">Discover More</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    }

                    <div className="slider-content slide2">
                        <div className="container">
                            <div className="sl-sub-title white-color">World Leading University</div>
                            <h1 className="sl-title white-color">Educavo University</h1>
                            <div className="sl-btn">
                                <Link className="readon2 banner-style" to="/about">Discover More</Link>
                            </div>
                        </div>
                    </div>
                </Slider> */}
            </div >
        </React.Fragment >
    );
}

export default SliderStyleTwo;