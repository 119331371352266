import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

const Cta = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-cta style2" >
            <div className="partition-bg-wrap home2">
                <div className="container">
                    <div className="row y-bottom">
                        <div className="col-lg-12 pl-62 pt-134 pb-150 md-pl-14 md-pt-44 md-pb-50">
                            <SectionTitle
                                sectionClass="sec-title mb-40 md-mb-20 text-white"
                                titleClass="title mb-16 md-mb-10 text-white"
                                title={`Admission Open for ${new Date().getFullYear()}`}
                                descClass="desc text-white"
                                description="Apply Now for an exciting opportunity to unlock your potential and shape your future! Our institution is now accepting applications for our esteemed programs. Whether you're passionate about Management, Social Science, Computing, or any other field, this is your chance to take the first step towards a transformative educational journey."
                            />
                            <div className="btn-part">
                                <a className="readon2" href="https://admission.luxmundi.smartsourcing.ng/" target='_blank'>Apply Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cta;