import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SectionTitle from '../../components/Common/SectionTitle';

import gridImg1 from '../../assets/images/mundi2.jpg'
import gridImg2 from '../../assets/images/mundi7.jpg'
import { connect } from 'react-redux';

function About(props) {
    const [state, setState] = useState(true);
    const counters = [
        {
            countNum: 1,
            countTitle: 'Students',
            counterPrefix: 'k+',
        },
        {
            countNum: props.FacultyListDetails.length,
            countTitle: 'Faculties',
            counterPrefix: '',
        },
        {
            countNum: props.DepartmentListDetails.length,
            countTitle: 'Departments',
            counterPrefix: '',
        },

    ];
    return (
        <div id="rs-about" className="rs-about style2 pt-94 pb-100 md-pt-64 md-pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 pr-64 md-pr-14 md-mb-50">
                        <div className="about-intro">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-40"
                                subtitleClass="sub-title primary"
                                titleClass="title mb-22 white-color"
                                title="Welcome to Lux Mundi University"
                                descClass="desc big white-color"
                                description={<p dangerouslySetInnerHTML={{ __html: `${props.HomepageContentDetails.DiscoverContent?.substr(0, 100)}...` }} />}
                            />
                            {/* Section Title End */}
                            <div className="btn-part">
                                <Link to="/about-us" className="readon2">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 ml--40 md-ml-0">
                        {counters &&
                            <div className="row rs-counter couter-area mb-40">
                                {counters.map((counter, num) => (
                                    <div key={num} className="col-md-6">
                                        <div className="counter-item two">
                                            <h2 className="number rs-count">
                                                <CountUp start={state ? 0 : counter.countNum} end={counter.countNum} duration={10} onEnd={() => setState(false)} />
                                                {({ countUpRef, start }) => (
                                                    <VisibilitySensor onChange={start} delayedCall>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                                <span className="counter-prefix">{counter.counterPrefix}</span>
                                            </h2>
                                            <h4 className="title mb-0">{counter.countTitle}</h4>
                                        </div>
                                    </div>
                                )).slice(1, 2)}
                                {counters.map((counter, num) => (
                                    <div key={num} className="col-md-6">
                                        <div className="counter-item three">
                                            <h2 className="number rs-count">
                                                <CountUp start={state ? 0 : counter.countNum} end={counter.countNum} duration={10} onEnd={() => setState(false)} />
                                                {({ countUpRef, start }) => (
                                                    <VisibilitySensor onChange={start} delayedCall>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                                <span className="counter-prefix">{counter.counterPrefix}</span>
                                            </h2>
                                            <h4 className="title mb-0">{counter.countTitle}</h4>
                                        </div>
                                    </div>
                                )).slice(2, 3)}
                            </div>
                        }
                        <div className="row grid-area">
                            <div className="col-md-6 sm-mb-30">
                                <div className="image-grid">
                                    <img
                                        src={gridImg1}
                                        alt="grid img"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="image-grid">
                                    <img
                                        src={gridImg2}
                                        alt="grid img"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        HomepageContentDetails: state.HomepageContentDetails,
        FacultyListDetails: state.FacultyListDetails,
        DepartmentListDetails: state.DepartmentListDetails

    }
}

export default connect(mapStateToProps, null)(About);
