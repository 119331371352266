import React from 'react';
import Slider from "react-slick";
import SinglePostThree from './SinglePostThree';

import blogImg1 from '../../assets/img/blog/style2/1.jpg';
import blogImg2 from '../../assets/img/blog/style2/2.jpg';
import blogImg3 from '../../assets/img/blog/style2/3.jpg';
import blogImg4 from '../../assets/img/blog/style2/4.jpg';
import { connect } from 'react-redux';
import { useState } from 'react';
import { descDynamicSort, dynamicSort, formatDateAndTime, serverLink, shortCode } from '../../resources/constants';

const BlogPartTwo = (props) => {


    const blogSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const [news, setNews] = useState(props.NewsList.length > 0 ?
        props.NewsList.sort(dynamicSort("InsertedDate")) : [])

    return (
        <React.Fragment>
            <Slider {...blogSettings}>
                {
                    news.length > 0 ?
                        news.slice(0, 3).map((item, index) => {
                            return (
                                <SinglePostThree
                                    key={index}
                                    blogClass='blog-item'
                                    contentClass="blog-content new-style"
                                    blogImage={`${serverLink}public/uploads/${shortCode}/news_uploads/${item.ImagePath}`}
                                    blogTitle={item.Title}
                                    blogDesc={<p dangerouslySetInnerHTML={{ __html: item.Description?.substr(0, 100)+"..." }} />}
                                    blogPublishedDate={formatDateAndTime(item.InsertedDate, "date")}
                                    cmtQnty="15"
                                    blogAuthor={item.FacultyCode}
                                    slug={item.Slug}
                                />
                            )
                        })
                        :
                        <h3>
                            ...oops!, No News Data
                        </h3>
                }
            </Slider>
        </React.Fragment>
    );

}


const mapStateToProps = (state) => {
    return {
        NewsList: state.NewsDetails,
    };
};

export default connect(mapStateToProps, null)(BlogPartTwo);