import React from 'react';
import SinglePostSidebar from './SinglePostSidebar';
import SinglePostSix from '../../components/Blog/SinglePostSix';
import { descDynamicSort, dynamicSort, formatDateAndTime, serverLink, shortCode } from '../../resources/constants';
import { useState } from 'react';
import { connect } from 'react-redux';

const BlogMain = (props) => {

    const [newsList, setNewsList] = useState(props.NewsList.length > 0 ? props.NewsList.sort(dynamicSort("InsertedDate")) : []);

    const categories = [...new Set(props.newsList?.map((item) => { return item.FacultyCode }))]
    const onSearch = (e) => {
        const value = e.target.value;
        const searchResult = props.NewsList.length > 0 ?
            props.NewsList.filter(x => x.Title.toLowerCase().includes(value) ||
                x.Description.toLowerCase().includes(value)) : []
        setNewsList(searchResult)
    }

    const onCategoryChange = (value) => {
        const searchResult = props.NewsList.length > 0 ?
            props.NewsList.filter(x => x.FacultyCode.includes(value) ) : []
        setNewsList(value !== 'all' ? searchResult : props.NewsList)
    }
    return (
        <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-70 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 order-last">
                        <div className="widget-area">
                            <SinglePostSidebar onSearch={onSearch} categories={categories} onCategoryChange={onCategoryChange} isDetails={false} />
                        </div>
                    </div>

                    <div className="col-lg-8 pr-50 md-pr-16">
                        <div className='row'>
                            {
                                newsList.length > 0 &&
                                newsList.map((item, index) => {
                                    return (
                                        <div className='col-lg-12 mb-70 md-mb-50' key={index} >
                                            <SinglePostSix
                                                blogImage={`${serverLink}public/uploads/${shortCode}/news_uploads/${item.ImagePath}`}
                                                blogCategory='University'
                                                blogPublishedDate='January 21, 2022'
                                                blogTitle={item.Title}
                                                blogDesc={<p dangerouslySetInnerHTML={{ __html: item.Description?.substr(0, 100) + "..." }} />}
                                                blogButtonClass='blog-button'
                                                blogButtonText='Continue Reading'
                                                slug={item.Slug}
                                                newsDate={formatDateAndTime(item.InsertedDate, "date")}
                                                blogAuthor={item.FacultyCode}
                                            />
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        NewsList: state.NewsDetails,
    };
};

export default connect(mapStateToProps, null)(BlogMain);