import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import SectionTitle from '../../components/Common/SectionTitle';
import EventsShort from '../../components/Events/EventsShort';

// Images
import eventImage from '../../assets/images/events.jpg'
import { connect } from 'react-redux';
import { useState } from 'react';
import { currentDate, descDynamicSort, dynamicSort, formatDateAndTime } from '../../resources/constants';

const Events = (props) => {

    useEffect(() => {
        AOS.init();
    })

    const [events, setEvent] = useState(
        props.EventList.length > 0 ? props.EventList.sort(dynamicSort("EventDate")) : []
    )


    return (
        <div className="rs-latest-events style1 bg-wrap pt-100 md-pt-70 md-pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 pr-64 pt-24 md-pt-0 md-pr-14 md-mb-30">
                        {/* Section Title Start */}
                        {/* <SectionTitle
                            sectionClass="sec-title mb-42 md-mb-30 zIndex1"
                            subtitleClass="sub-title primary"
                            titleClass="title mb-0"
                            title="Events"
                        /> */}
                        {/* Section Title End */}
                        <div className="single-img">
                            <img
                                src={eventImage}
                                alt="Event Image"
                                style={{ height: "100%" }}
                            />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="event-wrap">
                            {
                                events.length > 0 ?
                                    events.slice(0, 3).map((item, index) => {
                                        const bg_c = ["bgc1", "bgc2", "bgc3"]
                                        const bg_color = bg_c[Math.floor(Math.random() * bg_c.length)];
                                        return (
                                            <EventsShort
                                                key={index}
                                                eventsClass="events-short mb-30"
                                                dateboxClass={`date-part ${bg_color}`}
                                                month={formatDateAndTime(item.EventDate, "short_month")} date={new Date(item.EventDate).getDate()}
                                                cat1="Math" cat2="English"
                                                title={item.Title}
                                                description={<p dangerouslySetInnerHTML={{ __html: `${item.Description?.substr(0, 100)}` }} />}
                                                slug={item.Slug}
                                                start={item.StartTime}
                                                end={item.EndTime}
                                                isEvent={true}


                                            />
                                        )
                                    })
                                    :
                                    <EventsShort
                                        eventsClass="events-short mb-30"
                                        dateboxClass="date-part bgc1"
                                        month={formatDateAndTime(currentDate, "short_month")} date={new Date(currentDate).getDate()}
                                        title="...oops!, kindly check back later"
                                        isEvent={false}
                                    />
                            }
                            {
                                events.length > 0 &&
                                <div className="btn-part mt-54 md-mt-24">
                                    <Link to="/events">View All Events</Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        EventList: state.EventDetails,
    };
};

export default connect(mapStateToProps, null)(Events);
