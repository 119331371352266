import React, { useState } from 'react'
import ModalVideo from 'react-modal-video';
import videoImg from '../../assets/img/about/about-video-bg2.png';
import { Link } from 'react-router-dom';
import ContactInfo from '../../components/Contact/ContactInfo';
import { projectAddress, projectEmail, projectPhone } from '../../resources/constants';


export default function FacultySideBar(props) {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);
    return (
        <div className="inner-column">
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='YLN1Argi7ik' onClose={() => { openModal(); }} />
            <div className="intro-video media-icon orange-color2">
                <img className="video-img" src={videoImg} alt="Video Image" />
                <Link className="popup-videos" onClick={() => { openModal(); }} >
                    <i className="fa fa-play"></i>
                </Link>
            </div>
            {
                props.courseList.length > 0 &&
                <div className="widget-area">
                    <div className="recent-posts mb-50">
                        <h3 className="widget-title">Our Courses</h3>
                        <ul>
                            {
                                props.courseList.length > 0 &&
                                props.courseList.map((x, i) => {
                                    return (
                                        <li className="mb-20" key={i}>
                                            <Link to={`/course/${x.Slug}`}>
                                                {x.CourseName} ({x.CourseClass} | {x.Duration}-{x.DurationType})
                                                <i className="fa fa-angle-right float-end text-dark" />
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            }
            <div className="widget-area">
                <div className="rs-contact style2 ">
                    <div className="contact-box">
                        <div className="row ">
                            <div className="col-lg-12 md-mb-30">
                                <div className="box-wraper">
                                    <h4 className="box-title text-start">Contact</h4>
                                    <ContactInfo
                                        boxClass="mb-30"
                                        title="Email Addresss"
                                        iconClass="fa fa-envelope-o"
                                        email={projectEmail}
                                    />
                                    <ContactInfo
                                        boxClass="mb-30"
                                        title="Phone Number"
                                        iconClass="fa fa-headphones"
                                        phone={projectPhone}
                                    />
                                    <ContactInfo
                                        boxClass=""
                                        title="Address"
                                        iconClass="fa fa-map-signs"
                                        address={projectAddress}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <br />
        </div>
    )
}
