import React from 'react';
import { Helmet } from 'react-helmet';
import BlogDetails from './BlogDetails';
import SinglePostSidebar from './SinglePostSidebar';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect } from 'react';

const SinglePostLeftSidebar = (props) => {
    const { slug } = useParams();
    const type = window.location.href.split("/")[3] === "news-details" ? "news" : "events"
    const pageData = type === "news" ? props.NewsList : props.EventList;

    const [newsdetails, setNewsdetails] = useState(pageData.length > 0 ?
        pageData.filter(x => x.Slug === slug)
        : []);

    useEffect(() => {
        setNewsdetails(pageData.length > 0 ?
            pageData.filter(x => x.Slug === slug)
            : [])
    }, [slug])

    const categories = [...new Set(pageData?.map((item) => { return item.FacultyCode }))]

    const [sideBarData, setSidebardata] = useState(pageData.length > 0 ? pageData : [])
    const onSearch = (e) => {
        const value = e.target.value;
        const searchResult = pageData.length > 0 ?
            pageData.filter(x => x.Title.toLowerCase().includes(value) ||
                x.Description.toLowerCase().includes(value)) : []
        setSidebardata(searchResult)
    }

    const onCategoryChange = (value) => {
        const searchResult = pageData.length > 0 ?
            pageData.filter(x => x.FacultyCode.includes(value)) : []
        setSidebardata(value !== 'all' ? searchResult : pageData)
    }

    return (
        <React.Fragment>

            {/* Blog Details Start */}
            <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 pr-50 md-pr-14">
                            <BlogDetails pagedetails={newsdetails} pageData={pageData} type={type} />
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="widget-area">
                                <SinglePostSidebar type={type} pageData={sideBarData} onSearch={onSearch} categories={categories} onCategoryChange={onCategoryChange} isDetails={true} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        NewsList: state.NewsDetails,
        EventList: state.EventDetails
    };
};

export default connect(mapStateToProps, null)(SinglePostLeftSidebar);



