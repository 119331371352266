import React, { useEffect, useState } from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import bannerbg from "../../assets/images/banner.jpg";
import axios from "axios";
import { serverLink, shortCode } from "../../resources/constants";

const Senate = () => {
    const [senateList, setSenateList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getSenateList() {
            const result = await axios.get(`${serverLink}leadership/senate/list`);
            setSenateList(result.data);
            setLoading(false);
        }
        getSenateList();
    });

    return (
        <React.Fragment>
            <OffWrap />

            <SiteBreadcrumb pageTitle="Senate"
                            pageName="Senate"
                            breadcrumbsImg={bannerbg} />

            <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">
                <div className="container">
                    <div className="col-12">
                        <h3 className="text-center" style={{color: "#273c66"}}><strong>Senate</strong></h3>
                        <div className="d-flex align-items-center align-content-between flex-column">
                            <div className="row">
                                {senateList?.map((senate, index) => (
                                    <div className="text-center col-md-4" key={index}>
                                        <img
                                            style={{ height: 350, width: 400 }}
                                            src={`${serverLink}public/uploads/${shortCode}/leaderships_uploads/${senate.ImagePath}`}
                                            alt={senate.FullName} className="img-thumbnail"
                                        />
                                        <h5 className="my-2" style={{color: "#273c66"}}>
                                            <strong>{senate.Title} {senate.FullName}</strong>
                                        </h5>
                                        <h6 style={{color: "#273c66"}}><strong>{senate.Designation}</strong></h6>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

        </React.Fragment>
    )
}

export default Senate;