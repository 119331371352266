import React from 'react';
import { Link } from 'react-router-dom';


const CourseSingleTwo = (props) => {
    const { courseClass, courseImg, courseTitle, catLink, coursePrice, courseCategory, userCount, courseData } = props;
    return (
        <div className={courseClass ? courseClass : 'courses-item'}>
            <div className="img-part">
                <img
                    src={courseImg}
                    alt={courseTitle}
                />
            </div>
            <div className="content-part">
                <ul className="meta-part">
                    <li><span className="price"style={{textTransform: 'capitalize'}}>{courseData.ApplicationType}</span></li>
                </ul>
                <h3 className="title"><Link to={`/course/${courseData.Slug}`}>{courseTitle ? courseTitle : 'Become a PHP Master and Make Money Fast'}</Link></h3>
                <div className="bottom-part">
                    <div className="info-meta">
                        <ul>
                            <li className="user"><i className="fa fa-clock-o"/> {courseData.Duration} {courseData.DurationType} </li>
                            <li className="user"><i className="fa fa-graduation-cap"/> {courseData.CourseClass}</li>
                        </ul>
                    </div>
                    <div className="btn-part">
                        <Link to={`/course/${courseData.Slug}`}>
                            {props.btnText}<i className="flaticon-right-arrow"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseSingleTwo