import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

const CourseSidebar = (props) => {
    const app_type = [];
    if (props.courseList.length > 0) {
        props.courseList.map((course) => {
            app_type.push(course.ApplicationType);
        });
    }
    const unique_app_type = app_type.filter((v, i, a) => a.indexOf(v) === i);

    useEffect(()=>{
        console.log(unique_app_type)
    }, [])

    return (
        <React.Fragment>
            <div className="widget-area">
                <div className="search-widget mb-50">
                    <h3 className="widget-title">Course Search</h3>
                    <div className="search-wrap">
                        <input type="search" onChange={props.onCourseChange}  placeholder="Find Your Course" className="search-input" />
                    </div>
                </div>

                <div className="widget-archives md-mb-50">
                    <h3 className="widget-title">Filter By</h3>
                    <h6 className="small">Faculties</h6>
                    <ul className="categories">
                        <li onClick={() =>
                                props.handleOnFacultyClick("")
                            }>
                            <Link to={"/course-finder"} >
                                All Courses ({
                                props.courseList.length
                            })
                            </Link>
                        </li>
                        {props.facultyList.length > 0 &&
                        props.facultyList.map((faculty, index) => {
                            return (
                                <li
                                    key={index}
                                    onClick={() =>
                                        props.handleOnFacultyClick(faculty.FacultyCode)
                                    }
                                >
                                    <Link to={"/course-finder"}>
                                        {faculty.FacultyName} (
                                        {
                                            props.courseList.filter(
                                                (item) => item.FacultyCode === faculty.FacultyCode
                                            ).length
                                        }
                                        )
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );

}

const mapStateToProps = (state) => {
    return {
        facultyList: state.FacultyListDetails,
        courseList: state.CourseListDetails,
    };
};

export default connect(mapStateToProps, null)(CourseSidebar);
