import React from 'react';
import Commenting from '../../components/Widget/Commenting';
import PostContent from '../../components/Widget/PostContent';
import PostNavigation from '../../components/Widget/PostNavigation';

const BlogDetails = (props) => {
    let current_index = props.pageData.map((x) => { return x.Slug }).indexOf(props.pagedetails[0]?.Slug);
    let next_item = props.pageData.length > current_index + 1 ? props.pageData[current_index + 1] : props.pageData[0]

    return (
        <>
            <PostContent pagedetails={props.pagedetails} type={props.type} />
            {
                next_item.Slug !== undefined &&
                <PostNavigation item={next_item} type={props.type} />
            }
        </>
    );
}

export default BlogDetails;