import React from 'react';
import { Link } from 'react-router-dom';
import * as DOMPurify from 'dompurify';

const OverviewPart = (props) => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <div
                        style={{ textAlign: "justify" }}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(props.Description),
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;