import React, { useState, useCallback } from "react";
import { Link } from 'react-router-dom';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";


const SideModal = (props) => {
    const { canvasClass, canvasLogo, children } = props;

    const canvasMenuRemove = () => {
        document.body.classList.remove('nav-expanded');
    };


    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <React.Fragment>
            <nav className={canvasClass}>
                <div className="close-btn">
                    <div onClick={canvasMenuRemove} id="nav-close">
                        <div className="line">
                            <span className="line1"></span><span className="line2"></span>
                        </div>

                    </div>
                </div>
                <div className="canvas-logo">
                    <Link to="/">
                        <img src={canvasLogo} alt="logo"
                        />
                    </Link>
                </div>
                {children}
            </nav>
        </React.Fragment>
    );
}

export default SideModal;