
import { Link } from 'react-router-dom';

const SinglePostSix = (props) => {
    const { blogClass, blogImage, blogTitle, blogAuthor, blogPublishedDate, blogCategory, blogDesc, blogButtonClass, blogButtonText } = props;
    return (
        <div className={blogClass ? blogClass : 'blog-item'}>
            <div className="blog-img">
                <Link to={`/news-details/${props.slug}`}>
                    <img
                        src={blogImage}
                        alt={blogTitle}
                        style={{ minWidth: "100%", maxWidth: "60%", minHeight: "350px", maxHeight: "350px" }}
                    />
                </Link>
            </div>
            <div className="blog-content">
                <h3 className="blog-title">
                    <Link to={`/news-details/${props.slug}`}>
                        {blogTitle ? blogTitle : 'University while the lovely valley team work'}
                    </Link>
                </h3>
                <div className="blog-meta">
                    <ul className="btm-cate">
                        <li>
                            <div className="blog-date">
                                <i className="fa fa-calendar-check-o"></i> {props.newsDate}
                            </div>
                        </li>
                        <li>
                            <div className="author">
                                <i className="fa fa-user-o"></i> {blogAuthor ? blogAuthor : 'Admin'}
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="blog-desc">
                    {blogDesc}
                </div>
                <div className={blogButtonClass ? blogButtonClass : 'blog-button'}>
                    <Link to={`/news-details/${props.slug}`} className="blog-btn">
                        {blogButtonText ? blogButtonText : 'Continue Reading'}
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default SinglePostSix