import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import CourseSidebar from './CourseSidebarSection';
import CourseSingleTwo from '../../components/Courses/CourseSingleTwo';
import { connect } from "react-redux";
import courseImg1 from '../../assets/img/courses/course.png';
import axios from "axios";
import {setCourseListDetails, setGeneralDetails} from "../../actions/setactiondetails";
import {serverLink} from "../../resources/constants";

function CoursePart(props) {
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [courseList, setCourseList] = useState(
        props.courseList.filter((e) =>
            e.CourseName.toLowerCase().includes(search.toLowerCase())
        )
    );
    const [courses, setCourses] = useState(props.courseList);

    const getData = async () => {
        await axios
            .get(`${serverLink}academics/course/view/list`)
            .then((result) => {
                const data = result.data;
                setLoading(false);
                setCourseList(data);
                props.setOnCourseDetails(data);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getData();
    }, [""]);
    useEffect(() => {
        setCourseList(
            props.courseList.filter((e) =>
                e.CourseName.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search]);

    const onCourseChange = (e) => {
        const selectedCourse = e.target.value;
        setSearch(selectedCourse);
    };

    const handleOnFacultyClick = (e) => {
        if (e !== ""){
            setCourseList(props.courseList.filter((item) => item.FacultyCode === e));
        }else{
            setCourseList(props.courseList);
        }

    };

    const handleOnApplicationTypeClick = (e) => {
        setCourseList(
            props.courseList.filter((item) => item.ApplicationType === e)
        );
    };



    const listClassAdd = () => {
        document.getElementById("rs-popular-course").classList.add('list-view');
    };

    const listClassRemove = () => {
        document.getElementById("rs-popular-course").classList.remove('list-view');
    };

    return (
        <div id="rs-popular-course" className="rs-popular-courses style1 course-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 order-last">
                        <CourseSidebar
                            courseList={courses}
                            onCourseChange={onCourseChange}
                            handleOnFacultyClick={handleOnFacultyClick}
                            handleOnApplicationTypeClick={handleOnApplicationTypeClick} />
                    </div>
                    <div className="col-lg-8 pr-50 md-pr-14">
                        <div className="course-search-part">
                            <div className="course-view-part">
                                <div className="view-icons">
                                    <button onClick={listClassRemove} className="view-grid mr-10"><i className="fa fa-th-large"/></button>
                                    <button onClick={listClassAdd} className="view-list"><i className="fa fa-list-ul"/></button>
                                </div>
                                <div className="view-text">Showing {courseList.length} of {props.courseList.length} results</div>
                            </div>
                            <div className="type-form">
                                <form method="post" action="#">
                                    <div className="form-group mb-0">
                                        <div className="custom-select-box">
                                            <select id="timing">
                                                <option>Undergraduate</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="course-part clearfix">
                            {
                                courseList.length > 0 ?
                                    courseList.map((item, key) => {
                                        return (<div>
                                            <CourseSingleTwo
                                                courseClass="courses-item"
                                                courseImg={courseImg1}
                                                courseTitle={item.CourseName}
                                                courseData={item}
                                                coursePrice="$40.00"
                                            />
                                        </div>)
                                    })
                                    :
                                    <></>
                            }

                        <div className="pagination-area orange-color text-center mt-30 md-mt-0">
                            <ul className="pagination-part">
                                <li className="active"><Link to="#">1</Link></li>
                                <li><Link to="#">2</Link></li>
                                <li><Link to="#">Next <i className="fa fa-long-arrow-right"/></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
   );
}


const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnCourseDetails: (p) => {
            dispatch(setCourseListDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CoursePart);