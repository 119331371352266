import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/main.scss';
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import rootReducer from "./reducers/rootReducer";
import { logger } from "redux-logger/src";
import { status } from './resources/constants';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store;
if (status === "Dev") {
	store = createStore(
		rootReducer,
		composeEnhancers(applyMiddleware(logger, thunkMiddleware))
	);
} else {
	store = createStore(
		rootReducer,
		composeEnhancers(applyMiddleware(thunkMiddleware))
	);
}

const persist = persistStore(store);
const root = createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<PersistGate persistor={persist}>
			<App />
		</PersistGate>
	</Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
