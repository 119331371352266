import React from 'react';
import { Link } from 'react-router-dom';

const EventsShort = (props) => {
    const { eventsClass, dateboxClass, catLink1, catLink2, titleClass, titleLink, animateName, animateDelay } = props;
    return (
        <div className={eventsClass ? eventsClass : 'events-short mb-10'} data-aos={animateName ? animateName : 'fade-up'} data-aos-delay={animateDelay ? animateDelay : ''}>
            <div className={dateboxClass ? dateboxClass : 'date-part bgc1'}>
                <span className="month">{props.month}</span>
                <div className="date">{props.date}</div>
            </div>
            <div className="content-part">
                {props.isEvent &&
                    <div className="categorie">
                        <strong>Time :</strong> {props.start} -- {props.end}
                    </div>
                }
                <h4 className={titleClass}>
                    {
                        props.isEvent === true ?
                            <Link to={`/event-details/${props.slug}`}>{props.title}</Link> :
                            <span> {props.title}</span>
                    }
                </h4>
            </div>
        </div>
    )
}

export default EventsShort