import React from 'react';
import {Link} from "react-router-dom";


const PagesSidebar = () => {
    return (
        <div className="col-lg-4 padding-0 md-pl-14 md-pr-14 md-mb-30">
            <div className="widget-area">
                <div className="recent-posts mb-50">
                    <h3 className="widget-title">Additional Information</h3>
                    <ul>
                        <li className="mb-20">
                            <Link to={`/our-campus`}>
                                Our Campus
                                <i className="fa fa-angle-right float-end text-info" />
                            </Link>
                        </li>
                        <li className="mb-20">
                            <Link to={`/course-finder`}>
                                Courses
                                <i className="fa fa-angle-right float-end text-info" />
                            </Link>
                        </li>
                        <li className="mb-20">
                            <a href='https://admission.luxmundi.edu.ng/' target='_blank'>
                                Application
                                <i className="fa fa-angle-right float-end text-info" />
                            </a>
                        </li>
                        <li className="mb-20">
                            <Link to={`/gallery`}>
                                Gallery
                                <i className="fa fa-angle-right float-end text-info" />
                            </Link>
                        </li>
                        <li className="mb-20">
                            <Link to={`/news`}>
                                News
                                <i className="fa fa-angle-right float-end text-info" />
                            </Link>
                        </li>
                        <li className="mb-20">
                            <Link to={`/events`}>
                                Event
                                <i className="fa fa-angle-right float-end text-info" />
                            </Link>
                        </li>
                    </ul>
                    <div className="btn-part">
                        <a className="readon2 dark-hov col-md-12 text-center" href="https://admission.luxmundi.edu.ng/">Apply Now</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PagesSidebar;