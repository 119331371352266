import React from 'react';
import { Link } from 'react-router-dom';
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import bannerbg from "../../assets/images/banner.jpg";
import PagesSidebar from "../pages-sidebar/pages-sidebar";

const LearningGoals = () => {
    return (
        <React.Fragment>
            <OffWrap />

            <SiteBreadcrumb pageTitle="Learning Goals"
                            pageName="Learning Goals"
                            breadcrumbsImg={bannerbg} />
            <div className="rs-about style1 pt-82 md-pt-50 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 pr-70 md-pr-14 mb-20">
                            <div className="about-content">
                                {/* Section Title Start */}
                                <div className='sec-title mb-44 md-mb-0'>
                                    <div className="col-12">
                                        <p className="mb-4" style={{textAlign: "justify"}}>These learning goals reflect the holistic approach of Lux Mundi University, aiming to equip students with the knowledge, skills, and values necessary for personal and professional success, as well as societal impacts.</p>

                                        <h5 className="text-info mt-3">1. Knowledge Acquisition</h5>
                                        <p className="mb-4" style={{textAlign: "justify"}}>The primary goal of Lux Mundi University is to facilitate the acquisition of comprehensive knowledge in various disciplines. Students will be exposed to a wide range of subjects within their chosen faculties, ensuring they develop a strong foundation in their respective fields.</p>

                                        <h5 className="text-info mt-3">2. Critical Thinking and Problem-Solving</h5>
                                        <p className="mb-4" style={{textAlign: "justify"}}>Lux Mundi University aims to nurture students' critical thinking skills and their ability to solve complex problems. Through interactive and engaging learning experiences, students will be encouraged to analyze information, evaluate arguments, and propose innovative solutions to real-world challenges.</p>

                                        <h5 className="text-info mt-3">3. Communication and Presentation Skills</h5>
                                        <p className="mb-4" style={{textAlign: "justify"}}>Effective communication is crucial for success in today's interconnected world. Lux Mundi University will emphasize the development of strong oral and written communication skills. Students will learn to express their ideas clearly, present their research findings effectively, and engage in productive dialogue and debate.</p>

                                        <h5 className="text-info mt-3">4. Ethical and Social Responsibility</h5>
                                        <p className="mb-0" style={{textAlign: "justify"}}>Lux Mundi University is committed to instilling a sense of ethical and social responsibility in its students. They will be encouraged to consider the ethical implications of their actions and decision.</p>
                                        <h5 className="text-info mt-3">5. Research and Innovation</h5>
                                        <p className="mb-0" style={{textAlign: "justify"}}>Lux Mundi University recognizes the importance of research and innovation in advancing knowledge and driving progress. Students will be encouraged to engage in research projects, explore new ideas, and contribute to the development of their respective fields through creative and innovative approaches</p>

                                        <h5 className="text-info mt-3">6. Global and Cultural Awareness</h5>
                                        <p className="mb-0" style={{textAlign: "justify"}}>In an increasingly globalized world, Lux Mundi University will foster an appreciation for diverse cultures and perspectives.</p>

                                        <h5 className="text-info mt-3">7. Leadership and Entrepreneurship</h5>
                                        <p className="mb-0" style={{textAlign: "justify"}}>Lux Mundi University aims to nurture leadership qualities and entrepreneurial skills in its students. </p>

                                        <h5 className="text-info mt-3">8. Personal and Professional Development</h5>
                                        <p className="mb-0" style={{textAlign: "justify"}}>Lux Mundi University is committed to supporting the holistic development of its students.s. </p>

                                        <h5 className="text-info mt-3">9. Lifelong Learning</h5>
                                        <p className="mb-0">Lux Mundi University recognizes the importance of lifelong learning in a rapidly evolving world.. </p>

                                        <h5 className="text-info mt-3">10. Civic Engagement</h5>
                                        <p className="mb-0"> Lux Mundi University believes in the importance of active citizenship. </p>
                                    </div>
                                </div>
                                {/* Section Title End */}
                            </div>
                        </div>
                        <PagesSidebar/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default LearningGoals;