import React from 'react';
import { Link } from 'react-router-dom';

import defaultBG from '../../../assets/images/campus-footer.jpeg';
import { projectEmail, projectPhone } from '../../../resources/constants';

const FooterStyleTwo = (props) => {
    const { footerClass, footerTopClass, footerLogo, footerBG, footerColor } = props;

    const footerBGStyle = {
        backgroundImage: `url(${footerBG})`,
        backgroundColor: `url(${footerColor})`
    }
    const defaultBGStyle = {
        backgroundImage: `url(${defaultBG})`,
        backgroundColor: '#273c66'
    }


    return (
        <footer id="rs-footer" className={footerClass ? footerClass : 'rs-footer'} style={footerBG && footerColor ? footerBGStyle : defaultBGStyle}>
            <div className={footerTopClass ? footerTopClass : "footer-top"}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h4 className="widget-title">Explore</h4>
                            <p className="site-map text-white"  >
                                At Lux Mundi University, we are committed to providing a world-class education that opens doors to a bright future for our students. With a focus on high-quality integrated education, we aim to become one of the leading universities globally, promoting excellence, industry, and transforming lives on a global scale.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h4 className="widget-title">Resources</h4>
                            <ul className="site-map">
                                <li><Link to="/board-of-trustee">Board of Trustee</Link></li>
                                <li><Link to="/management">Management</Link></li>
                                <li><Link to="/senate">Senate</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h4 className="widget-title"><br /></h4>
                            <ul className="site-map">

                                <li><Link to="/about-us">About Us</Link></li>
                                <li><Link to="/our-campus">Our Campus</Link></li>
                                <li><Link to="/course-finder">Courses</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-12 col-sm-12 footer-widget">
                            <ul className="address-widget address2">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">
                                        New Ndagbo City, <br />
                                        Isiama Afaraukwu-Ibeku,
                                        Umuahia, Abia State, Nigeria.
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <a href={`tel:${projectPhone}`}>{projectPhone}</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <a href={`mailto:${projectEmail}`}>{projectEmail}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-6 md-mb-20">
                            <div className="copyright text-center md-text-left">
                                <p>© {new Date().getFullYear()} All Rights Reserved. <a href="/" target="_blank">Lux Mundi University</a></p>
                            </div>
                        </div>
                        <div className="col-lg-6 text-right md-text-left">
                            <ul className="footer-social">
                                <li>
                                    <Link to="#">
                                        <i className="fa fa-facebook-f"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <i className="fa fa-twitter"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <i className="fa fa-pinterest"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <i className="fa fa-google-plus"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <i className="fa fa-instagram"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterStyleTwo;