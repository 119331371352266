import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import SingleTestimonialTwo from '../../components/Testimonial/SingleTestimonialTwo';

import donorImg from '../../assets/img/donor/1.jpg';
import avatar1 from '../../assets/img/testimonial/style2/1.jpg';
import avatar2 from '../../assets/img/testimonial/style2/2.jpg';
import SectionTitle from '../../components/Common/SectionTitle';

function Testimonial() {

    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            <div className="rs-testimonial style2 pt-100 pb-100 md-pt-70 md-pb-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title mb-60 md-mb-30 text-center"
                        subtitleClass="sub-title primary"
                        titleClass="title mb-0"
                        title="Testimonials"
                    />
                    <div className="row">
                        <div className="col-lg-6 ml--14 md-ml-0">
                            <SingleTestimonialTwo
                                testiImage={avatar1}
                                name="ARCHBISHOP LUCIUS IWEJURU UGORJI "
                                designation="CHANCELLOR & CHAIRMAN"
                                description="Education is the passport to the future for tomorrow belongs to those who prepare for it today"
                            />
                        </div>
                        <div className="col-lg-6 ml--14 md-ml-0">
                            <SingleTestimonialTwo
                                animateDelay="50"
                                itemClass="testi-wrap"
                                testiImage={avatar2}
                                name="PROFESSOR GABRIEL OKENWA, OFR"
                                designation="FOUNDER & PRO CHANCELLOR"
                                description="Education is the passport to the future for tomorrow belongs to those who prepare for it today"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Testimonial