import React from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import Newsletter from '../../components/Common/Newsletter';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import TeamSingleMain from './TeamSingleMain';


import bannerbg from '../../assets/images/banner.jpg';

const StaffDetails = () => {
    return (
        <React.Fragment>
            <OffWrap />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Staff Details"
                pageName="Staff Details"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            {/* TeamDetails Start */}
            <TeamSingleMain />
            {/* TeamDetails End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default StaffDetails;