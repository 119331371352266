import React from 'react';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import CourseMain from './CourseMain';

import bannerbg from '../../assets/images/banner.jpg';

const CourseOne = () => {

    return (
        <React.Fragment>
            <OffWrap />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Course Finder"
                pageName="Course"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-start */}

            {/* Course Main */}
            <CourseMain />
            {/* Course Main */}

        </React.Fragment>
    );
}

export default CourseOne;